let serverAddress
// serverAddress = "https://zlotetarasy.s.sprin.tech/";
// serverAddress = "https://phytopharm.s.sprin.tech/";
// serverAddress = "https://s4.sprintechlearning.com/";
// serverAddress = "https://kpmg.s.sprin.tech/";
// serverAddress = "https://s.ricoh.sprin.tech/";
// serverAddress = "https://dev.s.sprin.tech/";
// serverAddress = "https://swieta.s.sprin.tech/";
// serverAddress = "https://demo.s.sprin.tech/";
// serverAddress = "https://hr.s.sprin.tech/";
// serverAddress = "http://localhost:8888/"
// serverAddress = "http://192.168.0.4:8888/"
serverAddress = "https://sv.sprin.tech/"
// serverAddress = "https://santander-sv.sprin.tech/"
// serverAddress = "https://s.sprin.tech/"

const CONFIG = {
  SERVER_ADDRESS: serverAddress,
  // INSTANCE_NAME: "hrsprintlearning",
  // INSTANCE_NAME: "canpack",
  // INSTANCE_NAME: "enge",
  // INSTANCE_NAME: "breastcancer",
  INSTANCE_NAME: "rakpiersi",
  // INSTANCE_NAME: "breast-cancer-gr",
  // INSTANCE_NAME: "breast-cancer-ro",
  // INSTANCE_NAME: "breast-cancer-hu",
  // INSTANCE_NAME: "values",
  // INSTANCE_NAME: "imposter",
  // INSTANCE_NAME: "szymon",
  // INSTANCE_NAME: "dysfunkcje",
  // INSTANCE_NAME: "phytopharm",

  // LANGUAGE: "en",
  LANGUAGE: "pl",
  // LANGUAGE: "gr",
  // LANGUAGE: "ro",
  // LANGUAGE: "hu",

  // SESSION_ID: "226",
  // SESSION_ID: "225",

  // en
  // SESSION_ID: "201",
  // SESSION_ID: "215",
  // pl
  // SESSION_ID: "203",
  // SESSION_ID: "217",

  ANIMATION_SPEED_MS: 1000,
  TIME_TO_READ_FEEDBACK_MS: 6000,

  // GA_ID: "UA-183086207-1", // CANPACK
  NONINVASIVE_CONSENT: true,
  // SHOW_CONSENT: true,
  SHOW_CONSENT: false,
}

export default CONFIG
